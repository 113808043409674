<template>
  <div>
    <div class="head-background">
      <span class="title">差旅政策</span>
    </div>
    <van-image width="100%" src="./policy.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  }
};
</script>

